<i18n lang="yaml">
ru:
  password_field: 'Пароль'
  login_button: 'Войти'
  remember_checkbox: 'Запомнить меня'
  reset_password: 'Восстановить пароль'
en:
  password_field: 'Password'
  login_button: 'Login'
  remember_checkbox: 'Remember me'
  reset_password: 'Reset password'
</i18n>

<template>
  <div class="container login" v-if="!$noCartMode()">
    <site-text-block type="login" class="login-text-block" />
    <form class="form" @submit.prevent="submitLogin">
      <field-text v-model="login" :value="login" :text="$t('login')" :errors="errors.email" @clear-errors="clearErrors('email')" />
      <field-password v-model="password" :value="password" :text="$t('password_field')" :errors="errors.password"
                      @clear-errors="clearErrors('password')" :show-icon="true" :show-password="showPassword"
                      @changeTypeField="changeTypeField($event)" />

      <div class="button-forgot">
        <button :class="{'disabled': !login || !password}">{{ $t('login_button') }}</button>
        <div>
          <div class="checkbox">
            <label :class="{'checked': remember}">
            <input class="styled-checkbox" type="checkbox" v-model="remember">
            {{ $t('remember_checkbox') }}</label>
          </div>
          <div class="link-reset">
            <nuxt-link :to="localePath('/password-reset/')">{{ $t('reset_password') }}</nuxt-link>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import Cookies from 'js-cookie';

import FieldPassword from '@/components/field-password';
import FieldText from '@/components/field-text';
import SiteTextBlock from '@/components/blocks/site-text-block';

export default {
  components: {
    FieldPassword,
    FieldText,
    SiteTextBlock,
  },
  middleware: 'auth',
  auth: 'guest',
  data() {
    return {
      login: null,
      password: null,
      remember: false,
      errors: {},
      showPassword: false
    }
  },
  head() {
    return {
      title: this.$t('login'),
    }
  },
  mounted(){
    if(this.$noCartMode()){
      this.$nuxt.context.error({statusCode: 404});
      return
    }
  },
  methods: {
    clearErrors(field) {
      this.errors[field] = [];
    },
    submitLogin() {
      Cookies.remove('cookie-terms-session'); // окно Помощь в рамках сессии

      this.$auth.loginWith('local', {
        data: {email: this.login, password: this.password},
      }).then((response) => {
        this.$store.dispatch('setCartExists');
        this.$store.dispatch('setPriceRequestExists');
        if (this.remember) {
          const cookieExpire = parseInt(process.env.COOKIE_EXPIRE);
          this.$auth.$storage.setCookie('remember_expires', cookieExpire, { expires: cookieExpire });
        }
      }).catch((e) => {
        try {
          this.errors = e.response.data;
          if (this.errors.non_field_errors) {
            this.$notify({
              duration: 5000,
              text: this.errors.non_field_errors.join(' '),
            });
          }
        } catch(e) {
          this.$notify({
            duration: 5000,
            text: this.$t('unknown_error'),
          });
        }
      });
    },
    changeTypeField (showPassword) {
     this.showPassword = showPassword;
    }
  },
}
</script>
