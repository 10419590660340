<template>
  <div class="field field-password" :class="{'error': errors && errors.length, 'disabled': disabled}">
    <label class="error-label" v-if="errors && errors.length && !value">{{ errors[0] }}</label>
    <input :type="showPassword ? 'text' : 'password'" @input="updateField" :placeholder="text"
           @focusin="$emit('clear-errors')" :autocomplete="newPassword ? 'new-password' : false" :disabled="disabled" />
    <label v-if="errors && errors.length && value">{{ errors[0] }}</label>
    <label v-else-if="errors && errors.length && !value">{{ text }}</label>
    <label v-else>{{ text }}</label>
    <a @click="changeTypeField" class="hide-show" v-if="showIcon">
      <i class="svg-icon" :class="{'eye': !showPassword,  'eye-crossed': showPassword}"></i>
    </a>
  </div>
</template>

<script>
export default {
  props: ['value', 'text', 'errors', 'showIcon', 'showPassword', 'newPassword', 'disabled'],
  methods: {
    updateField(e) {
      this.$emit("input", e.target.value);
    },
    changeTypeField() {
      this.$emit('changeTypeField', !this.showPassword);
    },
  }
}
</script>
